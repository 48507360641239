import { MenuHorizontalService } from '@core/_base/layout/services/menu-horizontal.service';
import { MenuOptions } from '@core/_base/layout/directives/menu.directive';
import { OffcanvasOptions } from '@core/_base/layout/directives/offcanvas.directive';
import { Component, OnInit, ChangeDetectorRef, DoCheck } from '@angular/core';
import { HtmlClassService } from '../html-class.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as objectPath from 'object-path';

@Component({
	selector: 'menu-horizontal',
	templateUrl: './menu-horizontal.component.html',
	styleUrls: ['./menu-horizontal.component.scss'],
})
export class MenuHorizontalComponent implements OnInit, DoCheck {
	// Public properties
	currentRouteUrl: any = '';

	rootArrowEnabled!: boolean;

	menuOptions: MenuOptions = {
		submenu: {
			desktop: 'dropdown',
			tablet: 'accordion',
			mobile: 'accordion',
		},
		accordion: {
			slideSpeed: 200, // accordion toggle slide speed in milliseconds
			expandAll: false, // allow having multiple expanded accordions in the menu
		},
		dropdown: {
			timeout: 50,
		},
	};

	offcanvasOptions: OffcanvasOptions = {
		overlay: true,
		baseClass: 'kt-header-menu-wrapper',
		closeBy: 'kt_header_menu_mobile_close_btn',
		toggleBy: {
			target: 'kt_header_mobile_toggler',
			state: 'kt-header-mobile__toolbar-toggler--active',
		},
	};

	constructor(public htmlClassService: HtmlClassService, public menuHorizontalService: MenuHorizontalService, private readonly router: Router, private readonly cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.rootArrowEnabled = true;
		this.currentRouteUrl = this.router.url;
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
			this.currentRouteUrl = this.router.url;
			this.cdr.markForCheck();
		});
		// console.log(this.menuHorizontalService.loadMenu().value);
	}

	ngDoCheck(): void {
		if (this.currentRouteUrl !== this.router.url) {
			console.log(this.currentRouteUrl);
			console.log(this.router.url);
		}
	}

	getItemCssClasses(item: any) {
		let classes = 'kt-menu__item';

		if (objectPath.get(item, 'submenu')) {
			classes += ' kt-menu__item--submenu';
		}

		if (!item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--active kt-menu__item--here';
		}

		if (item.submenu && this.isMenuItemIsActive(item)) {
			classes += ' kt-menu__item--open kt-menu__item--here';
		}

		if (objectPath.get(item, 'resizer')) {
			classes += ' kt-menu__item--resize';
		}

		const menuType = objectPath.get(item, 'submenu.type') || 'classic';
		if ((objectPath.get(item, 'root') && menuType === 'classic') || parseInt(objectPath.get(item, 'submenu.width'), 10) > 0) {
			classes += ' kt-menu__item--rel';
		}

		const customClass = objectPath.get(item, 'custom-class');
		if (customClass) {
			classes += ' ' + customClass;
		}

		if (objectPath.get(item, 'icon-only')) {
			classes += ' kt-menu__item--icon-only';
		}

		return classes;
	}
	getItemAttrSubmenuToggle(item: any) {
		let toggle = 'hover';
		if (objectPath.get(item, 'toggle') === 'click') {
			toggle = 'click';
		} else if (objectPath.get(item, 'submenu.type') === 'tabs') {
			toggle = 'tabs';
		} else {
			// submenu toggle default to 'hover'
		}
		return toggle;
	}
	getItemMenuSubmenuClass(item: any) {
		let classes = '';

		const alignment = objectPath.get(item, 'alignment') || 'right';

		if (alignment) {
			classes += ' kt-menu__submenu--' + alignment;
		}

		const type = objectPath.get(item, 'type') || 'classic';
		if (type === 'classic') {
			classes += ' kt-menu__submenu--classic';
		}
		if (type === 'tabs') {
			classes += ' kt-menu__submenu--tabs';
		}
		if (type === 'mega') {
			if (objectPath.get(item, 'width')) {
				classes += ' kt-menu__submenu--fixed';
			}
		}

		if (objectPath.get(item, 'pull')) {
			classes += ' kt-menu__submenu--pull';
		}
		return classes;
	}

	isMenuItemIsActive(item: any): boolean {
		if (item.submenu) {
			return this.isMenuRootItemIsActive(item);
		}

		if (!item.page) {
			return false;
		}

		return this.currentRouteUrl.indexOf(item.page) !== -1;
	}

	isMenuRootItemIsActive(item: any): boolean {
		if (item.submenu.items) {
			for (const subItem of item.submenu.items) {
				if (this.isMenuItemIsActive(subItem)) {
					return true;
				}
			}
		}

		if (item.submenu.columns) {
			for (const subItem of item.submenu.columns) {
				if (this.isMenuItemIsActive(subItem)) {
					return true;
				}
			}
		}

		if (typeof item.submenu[Symbol.iterator] === 'function') {
			for (const subItem of item.submenu) {
				const active = this.isMenuItemIsActive(subItem);
				if (active) {
					return true;
				}
			}
		}

		return false;
	}

	limitOrder(order: number, max: number = 5): number {
		return order > max ? max : order;
	}
}
