import { Breadcrumb, SubheaderService } from '@core/_base/layout/services/subheader.service';
import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'subheader',
	templateUrl: './subheader.component.html',
	styleUrls: ['./subheader.component.scss'],
	host: {
		class: 'kt-subheader kt-grid__item sumax-subheader',
		'[class.kt-container--clear]': 'clear',
		id: 'kt_subheader',
	},
	encapsulation: ViewEncapsulation.None,
})
export class SubheaderComponent implements OnInit, OnDestroy, AfterViewInit {
	bartype: any;

	// Public properties
	@Input() fluid!: boolean;
	@Input() clear!: boolean;

	today: number = Date.now();
	title = '';
	desc = '';
	breadcrumbs: Breadcrumb[] = [];

	// Private properties
	private subscriptions: Subscription[] = [];

	constructor(public subheaderService: SubheaderService) {}

	ngOnInit() {
		this.title = 'Carga';
	}
	ngAfterViewInit(): void {
		this.subscriptions.push(
			this.subheaderService.title$.subscribe((bt) => {
				// breadcrumbs title sometimes can be undefined
				if (bt) {
					Promise.resolve(null).then(() => {
						// this.title = bt.title;
						this.title = 'Facturas';
						this.desc = bt?.desc || '';
					});
				}
			})
		);

		this.subscriptions.push(
			this.subheaderService.breadcrumbs$.subscribe((bc) => {
				Promise.resolve(null).then(() => {
					this.breadcrumbs = bc;
				});
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}
}
