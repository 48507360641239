// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
// RxJS
import { BehaviorSubject, Observable, throwError, from } from 'rxjs';
import { AuthService } from '@core/security/services/auth.service';
import { switchMap, filter, take, catchError } from 'rxjs/operators';
import { MessageUtilService } from '~shared/services/message-util.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import { Logout } from '../../store/actions/auth.actions';
import { CookiesService } from '~shared/services/coookies.service';
import { getCloneRequest } from './interceptors';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { ValidateUtil } from '@utils/classes/ValidateUtil';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerInterceptorService implements HttpInterceptor {
	validateUtil = new ValidateUtil();
	refreshTokenInProgress = false;
	tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly _authService: AuthService,
		private readonly _cookiesService: CookiesService,
		private readonly _messageUtilService: MessageUtilService,
		private readonly _store: Store<AppState>
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((e: HttpErrorResponse) => {
				if ((e instanceof HttpErrorResponse && e.status === 403) || (401 && typeof e.error !== 'undefined' && (e.error.codigo === '000046' || e.error.codigo === 'GEN000046'))) {
					if (!this.refreshTokenInProgress) {
						this.refreshTokenInProgress = true;
						this.tokenSubject.next(false);
						return this._authService.refreshToken().pipe(
							switchMap((authResponse) => {
								this.refreshTokenInProgress = false;

								const { idToken, accessToken, maestroSyncToken } = authResponse;

								this._authService.updateTokens(idToken, accessToken, maestroSyncToken);

								this.tokenSubject.next(true);

								return next.handle(getCloneRequest(this._cookiesService, req));
							}),
							catchError((e: HttpErrorResponse) => {
								this.refreshTokenInProgress = false;
								return throwError(e);
							})
						);
					} else {
						return this.tokenSubject.pipe(
							filter((idToken) => idToken !== false),
							take(1),
							switchMap(() => {
								return next.handle(getCloneRequest(this._cookiesService, req));
							})
						);
					}
				}
				return this._throwError(e);
			})
		);
	}

	private _throwError(err: HttpErrorResponse) {
		if (!this.validateUtil.isUndefinedOrNull(err.status)) {
			if (err.status === 403 || err.status === 401) {
				if (typeof err.error !== 'undefined' && (err.error.codigo === '000046' || err.error.codigo === 'GEN000046')) {
					const errorResponse: ApiResponse = err.error;
					this._messageUtilService.error(errorResponse.mensaje, errorResponse.titulo);
				} else {
					if (err.message) {
						this._messageUtilService.error(err.message);
					} else {
						this._messageUtilService.error('Acceso Denegado');
					}
				}
			} else if (err.status === 500) {
				//Excepciones controladas del back
				if (!this.validateUtil.isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'A' && err.error.mensaje) {
					this._messageUtilService.warning(err.error.mensaje, err.error.titulo);
					//console.log("1")
				} else if (!this.validateUtil.isUndefinedOrNull(err.error) && err.error.type && err.error.type === 'E' && err.error.mensaje) {
					this._messageUtilService.error(err.error.mensaje, err.error.titulo);
					//console.log("2")
					if (err.error.codigo === 'SRY100001') {
						// Usuario no autorizado
						this._store.dispatch(new Logout());
					}
				} else if (this.validateUtil.isUndefinedOrNull(err.error) || err.status.toString() === err.error.codigo) {
					this._messageUtilService.error(err.error && err.error.type && err.error.type != 'error' && err.error.mensaje ? err.error.mensaje : err.message);
					//console.log("3")
				} else if (!this.validateUtil.isUndefinedOrNull(err.error) && !err.ok) {
					this._messageUtilService.error(err.message);
					//console.log("4")
				}
			} else if (err.status === 0 && err.statusText === 'Unknown Error') {
				console.log(err);
				this._messageUtilService.error(`El servidor de busqueda no responde<br>${err.url}`);
			} else {
				this._messageUtilService.error(err.message);
			}
		}
		return throwError(err);
	}
}

export const errorHandlerInterceptorProviders = {
	provide: HTTP_INTERCEPTORS,
	useClass: ErrorHandlerInterceptorService,
	multi: true,
};
